
export default {
	props:["isLoading"],
	name: 'vc-loading',
	data() {
		return {
			show_loading: true,
			change_message: false,
		}
	},
	mounted(){
		
		setInterval(() => {
			this.change_message = !this.change_message;
		}, 6000);
	},
	watch: {
		isLoading(){
			this.show_loading = true;
			if( !this.isLoading ){
				setTimeout(() => {
					this.show_loading = false;
					setTimeout(() => {
						this.show_loading = null;
					}, 500);
				}, 300);
			}
			return this.show_loading;
		}
	}
}
