
import { mapGetters } from "vuex";
export default {
    components: {
        vcLoading: () => import("../../../components/pages/loading.vue"),
        breadcrumbs: () => import("../../../components/pages/breadcrumbs.vue"),
        formPassword: () => import("../../../components/pages/form-password.vue")
    },
    data() {
        return {
            isLoading: true
        };
    },
    async mounted(){
        this.isLoading = null;
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
            getterUser: 'getterUser',
        }),
        getUserEmail(){
            return this.getterUser?.email ? this.getterUser.email : null;
        }
    },
    methods: {
        updateLoading(dataLoading){
            
            this.isLoading = dataLoading;
        },
    }
}
