
export default {
	data() {
		return {
		}
	},
	async mounted() {

	},
	computed: {
	},
	methods:{
	}
}
