
import { mapGetters } from "vuex";
export default {
	components: {
		breadcrumbs: () => import("../../../components/pages/breadcrumbs.vue")
	},
	async mounted() {
	},
	computed: {
		...mapGetters({
			getterLogin: 'getterLogin',
		})
	}
};
