
import { mapGetters } from 'vuex';
export default {
	props: ["store_info"],
	data() {
		return {
			store_information: this.store_info,
			store_path: this.$route.path
		}
	},
	async mounted(){
		this.$root.$on('update-store-information', this.updateStoreInformation);
	},
	computed: {
		...mapGetters({
			getterCompanyData: 'getterCompanyData'
		}),
		getStorePath(){
			return this.store_path;
		},
		getAllPhones(){
			return ( this.getterCompanyData?.contact_information.phones || [] ).reduce( (previous_item, current_item, current_index, original_array) =>{

				if( current_item.calls ){
					previous_item.push({ calls: true, number: current_item.number, link: current_item.link });
				}
				if( current_item.sms ){
					previous_item.push({ sms: true, number: current_item.number, link: current_item.link });
				}
				if( current_item.whatsapp ){
					previous_item.push({ whatsapp: true, number: current_item.number, link: current_item.link });
				}
				if( current_index == original_array.length - 1 ){
					previous_item = previous_item.filter( (item) => item.calls ).concat( previous_item.filter( (item) => item.sms ) ).concat( previous_item.filter( (item) => item.whatsapp ) );
				}
				return previous_item;
			}, []);
		}
	},
	methods: {
		updateStoreInformation(storeInfoData){
			
			this.store_information = storeInfoData;
		}
	}
}
