
import { mapGetters } from 'vuex';
import { arrayHelpers } from '../../constants/config';
export default {
	props: ["type_logo"],
	data() {
		return {
			show_mobile_carousel: false
		}
	},
	mounted(){
		this.show_mobile_carousel = window.innerWidth <= 1200;
		window.addEventListener('load', this.resizewindow);
		window.addEventListener('resize', this.resizewindow);
	},
	computed:{
		...mapGetters({
			getterCompanyData	: 'getterCompanyData',
		}),
		getSlideshow(){
			
			let slideshow = this.getterCompanyData?.slideshows?.brands ? this.getterCompanyData : null;
			
			slideshow = slideshow ? slideshow.slideshows.brands : null;
			
			if( slideshow ){
				
				return {
					desktop: arrayHelpers.chunkArray( JSON.parse( JSON.stringify( slideshow.images ) ), 3 ),
					mobile: arrayHelpers.chunkArray( JSON.parse( JSON.stringify( slideshow.images ) ), 2 )
				}
			}
			else{
				
				return null;
			}
		},
		getShowMobileCarousel(){
			return this.show_mobile_carousel;
		}
	},
	methods: {
		resizewindow(e){
			this.show_mobile_carousel = window.innerWidth <= 1200;
		},
	}
}
