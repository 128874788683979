
export default {
	props: {
		'type'			: { type: String, default: 'pages' },
		'title'			: { type: String, default: null }, 
		'subtitle'		: { type: String, default: null },
		'subItemData'	: { type: Object, default: null }
	}, 
	mounted(){
	}
}
