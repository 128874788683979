
import moment from "moment";
import { mapGetters } from 'vuex';
export default {
	props: ["product", "index", "imgList", "handleCollection", "hideDescription"],
	components: {
		vcLoading		: () => import('../../components/pages/loading.vue'),
		vcFormatPrice	: () => import('../../components/product/format-price.vue'),
        countDown   	: () => import( '../../components/pages/count-down.vue' )
	},
	data() {
		return {
			back_url: process.env.VUE_APP_HEFESTO_API_URL_FILE,
			default_images: [
				{ width: 180, alt: this.product.title, src: '/images/products/product-default_320.webp' },
				{ width: 320, alt: this.product.title, src: '/images/products/product-default_320.webp' }
			]
		};
	},
	mounted() {
		if( this.product.bundle ){
			this.product.bundle.time_left = moment(this.product.bundle.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
		}
	},
	computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
        }),
		getCollectionImage01(){

			return this.product.images?.length > 0 ? this.product.images[0].src_collection : `${ this.back_url }${ this.default_images[0].src }`;
		},
		getCollectionImage02(){

			return this.product.images?.length > 1 ? this.product.images[1].src_collection : `${ this.back_url }${ this.default_images[1].src }`;
		},
		getProductBundle(){
			if( this.product.bundle ){

				this.product.bundle.time_left = moment(this.product.bundle.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
			}
			return this.product.bundle;
		},
	},
	methods:{
		showModalVariants(){
			this.$root.$emit('show-modal-variants', this.product);
		}
	}
};
