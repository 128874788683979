
import { mapGetters, mapActions } from "vuex";
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	},
	data() {
		return {
			back_url: process.env.VUE_APP_HEFESTO_API_URL_FILE
		}
	},
	async mounted(){
		await this.getCatalogsElment();
	},
	computed: {
		...mapGetters({
			getterCatalogsElement: 'getterCatalogsElement'
		}),
	},
	methods:{
		...mapActions({
			getCatalogsElment: 'getCatalogsElment'
		})
	}
}
