
import { mapGetters, mapActions } from 'vuex';
export default {
	props: ["layout"],
	data() {
		return {
			showResults	: false,
			searchText	: '',
			openSearch	: false,
			showSearch	: false
		}
	},
	components: {
		profileImage	: () => import('../account/profile-image.vue'),
		searchResult	: () => import('../../components/header/search-result.vue')
	},
	async mounted() {
		await this.loginUserEvent();
		
		window.addEventListener('load', this.resizewindow);
		window.addEventListener('resize', this.resizewindow);
		await this.chargeSearchList(true);
		this.$root.$on('login-user', this.loginUserEvent);
		this.$root.$on("close-search-result", this.closeSearch);
		this.$root.$on("show-results-search", this.showResultsSearch);
		this.$root.$on("show-desktop-search", this.chargeSearchList);
		
	},
	computed: {
		...mapGetters({
			getterLogin				: 'getterLogin',
			getterCompanyData		: 'getterCompanyData',
			getterCartSaveLater		: 'getterCartSaveLater',
			getterSearchProduct		: 'getterSearchProduct',
			getterUser				: 'getterUser',
			getterLevel				: 'getterLevel'
		}),
		getSearchText(){
			return this.searchText;
		},
		getShowSearch(){
			return this.showSearch;
		}
	},
	methods: {
		...mapActions({
			getCart			: 'getCart',
			getLevelCustomer: 'getLevelCustomer',
			searchProduct	: 'searchProduct',
			logoutUser		: 'logoutUser',
		}),
		async loginUserEvent(isLogin){
			
			if( this.layout == 'default' && this.getterLogin ){
				
				if( this.$route.path.indexOf('/account/cart') < 0 && this.$route.path.indexOf('/checkout/cart') ){
					
					await this.getCart();
				}
				await this.getLevelCustomer();
			}
		},
		resizewindow(event){
			this.openSearch = window.innerWidth <= 1200;
		},
		async chargeSearchList( value ){
			
			if( value ){

				await this.searchProduct().then( (result) => {
					this.showSearch = true;
					this.$root.$emit("show-mobile-search", true);
				});
			}
			else{
				this.showSearch = false;
				this.$root.$emit("show-mobile-search", false);
			}
		},
		closeSearch() {
			this.openSearch = false;
			this.searchText = "";
			this.showResults = null;
		},
		showResultsSearch(dataResults){
			this.showResults = dataResults;
		},
		async searchProductWidget(event) {
			
			this.$cookies.set('value-search', this.getSearchText, { path: '/', maxAge: 60 * 60 * 24 * 7 });
			
			if( event.keyCode == 13 ){
				if (process.client) {
					this.closeSearch();
					this.$root.$emit('execute-view-more');
					// this.$router.replace({ path: `/collections/search`, query: { result: this.getSearchText } });
				}
			}
			else{
				
				const regex_search = new RegExp(`^(?=.*${ this.getSearchText })${ !this.getterLogin ? '(?!.*private-label)' : '' }.*`, 'i');
				this.$root.$emit('charge-search-result', { list: [...this.getterSearchProduct.filter( (item) => regex_search.test( item.search_field ) ).slice(0, 3)], search_text: this.getSearchText });
			}
		},
		cleanSearch(){
			this.searchText = "";
			this.showResults = null;
			document.getElementById("search_top_header").focus();
		},
		openAccount($event){
			this.$router.replace("/account/dashboard");
		},
		logout() {
			this.$root.$emit("show-desktop-search", false);
			this.$root.$emit("show-desktop-search", true);
			this.logoutUser();
			this.$router.push('/account/login');
		}
	}
}
