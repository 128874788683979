
import { mapActions, mapGetters } from "vuex";
export default {
	data() {
        return{
            show_pop_up: false
        };
	},
	async mounted() {
        setTimeout(() => {

            this.show_pop_up = true;
        }, 600);
	},
	computed: {
		...mapGetters({
			getterLogin: 'getterLogin',
            getterCompanyData: 'getterCompanyData',
		}),
        getShowPopUp(){
            return this.show_pop_up;
        },
        getPhoneWhatsapp(){
            return this.getterCompanyData?.contact_information.phones.find( (item) => item.whatsapp );
        }
	}
}
