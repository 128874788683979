
import { mapGetters, mapActions } from "vuex";
export default {
	data() {
		return {
			back_url: process.env.VUE_APP_HEFESTO_API_URL_FILE
		}
	},
	async mounted() {
		await this.getBrandsElment();
	},
	computed: {
		...mapGetters({
			getterBrandsElement: 'getterBrandsElement'
		})
	},
	methods: {
		...mapActions({
			getBrandsElment: 'getBrandsElment'
		})
	}
}
