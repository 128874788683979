
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { formatData } from "../../../constants/config.js";
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue"),
        basicTable  : () => import("../../../components/pages/basic-table.vue")
    },
    data() {
        return {
            isLoading           : true,
            table_fields	    : [
            { 
                key			: 'document_date', 
                label		: 'Date', 
                class		: "text-left w-15 d-none d-sm-table-cell", 
                formatter	: (value, key, item) => { 
                    
                    return moment(value.toString().split(".")[0]).format('lll');
                } 
            },
            { 
                key			: 'reference', 
                label		: 'Reference', 
                class		: "text-center w-15", 
                formatter	: (value, key, item) => { 
                    if( item.document == 'Invoice' ){
                        
                        return item.invoice?.order ? item.invoice.order.name : "";
                    }
                    else if( item.document == "Payment" ){
                        
                        return item.payment.note;
                    }
                    else if( item.document == "CreditNote" ){
                        
                        return item.creditNote.order ? item.creditNote.order.name : item.creditNote.reference;
                    }
                    else{
                        
                        return "";
                    }
                } 
            },
            { 
                key			: 'document', 
                label		: 'Document', 
                class		: "text-center w-15 d-md-table-cell d-none" 
            },
            { 
                key			: 'amount', 
                label		: 'Amount', 
                class		: "text-center w-15" 
            },
            { 
                key			: 'balance', 
                label		: 'Balance', 
                class		: "text-center w-15 d-none d-sm-table-cell" 
            },
            { 
                key			: 'status_name', 
                label		: 'Status', 
                class		: "text-center w-15 d-md-table-cell d-none" 
            },
            { 
                key			: 'actions', 
                label		: 'Actions', 
                class		: "text-center w-10 hide-title" 
            }
            ],
        };
    },
    computed: {
        ...mapGetters({
            getterLogin     : 'getterLogin',
            getterSatement  : "getterSatement",
        })
    },
    async mounted(){
        
        await this.getStatementByCustomer().then( (result_statement) => {
            
            this.isLoading = null;
        });
    },
    methods:{
        ...mapActions({
            getStatementByCustomer: "getStatementByCustomer"
        }),
        formatCurrency(price){
            return formatData.currency(price);
        }
    }
}
