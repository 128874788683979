
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			company_data	: null,
			preload_image	: [
				{
					image			: `/slide-image-01_full.webp`,
					alt				: 'slide-image-01',
					background_link	: "/account/register",
					loading			: "eager",
					show			: true
				}
			]
		}
	},
	async mounted() {
		window.addEventListener('load', this.loadSlider);
	},
	computed: {
		...mapGetters({
			getterCompanyData	: 'getterCompanyData',
			getterLogin			: 'getterLogin'
		}),
		getPreloadImages(){
			return this.preload_image;
		},
		getHomeSlider(){
			
			return this.company_data?.slideshows?.home ? this.company_data.slideshows.home : null;
		}
	},
	methods:{
		loadSlider(){
			setTimeout(() => {
				this.company_data = this.getterCompanyData?.slideshows?.home ? this.getterCompanyData : null;
				this.preload_image = (this.company_data?.slideshows?.home ? this.company_data.slideshows.home.images : []).reduce( (previous_item, current_item, current_index) => {
					if( (current_index == 0 && !this.getterLogin) || (current_index > 0 && current_item.show ) ){

						previous_item.push({
							image			: `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }/${ current_item.image.url }`,
							alt				: `slide-image-${ current_index + 1 }`,
							background_link	: current_item.background_link,
							loading			: current_index == 0 ? 'eager' : 'lazy',
							show			: current_item.show
						});
					}
					return previous_item;
				}, []);
			}, 3000);
		},
	}
}
