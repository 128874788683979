import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4c756db6"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDefault: require('C:/Users/Pedro Camacaro/Documents/proyectos-2b/cata1og-us-wholesale/store-vue2-main/components/header/header-default.vue').default,FooterDefault: require('C:/Users/Pedro Camacaro/Documents/proyectos-2b/cata1og-us-wholesale/store-vue2-main/components/footer/footer-default.vue').default})
