
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	},
	data() {
		return {
			aboutTitle: 'About Us',
			misionText: '',
			visionText: '',
			aboutText:
			''
		}
	}
}
