
import { mapGetters } from "vuex";
import { formatData } from "../../../constants/config.js";
import moment from "moment";
export default {
    components: {
        vcLoading   : () => import("../../../components/pages/loading.vue"),
        breadcrumbs : () => import("../../../components/pages/breadcrumbs.vue"),
        basicTable  : () => import("../../../components/pages/basic-table.vue")
    },
    data() {
        return {
            dataOrder: null,
            table_fields: [
            { key: 'created_at', label: 'Date', class: "w-15", formatter: (value, key, item) => { 
                return moment(value.toString().split(".")[0]).format('lll'); 
            } },
            { key: 'name', label: 'Order', class: "w-15" },
            { key: 'items', label: 'Items', class: "w-15 d-md-table-cell d-none", formatter: (value, key, item) => { 
                return item.line_items.reduce( (previous_item, current_item) => { previous_item += parseInt( current_item.quantity ); return previous_item; }, 0); 
            }, sortByFormatted: true, filterByFormatted: true },
            { key: 'total_price', label: 'Total', class: "w-15 d-md-table-cell d-none", formatter: (value, key, item) => { 
                return this.formatCurrency(value).format; 
            } },
            { key: 'financial_status', label: 'Financial Status', class: "w-15 d-none d-sm-table-cell" },
            { key: 'fulfillments', label: 'Fulfillment', class: "w-15 d-none d-sm-table-cell" },
            { key: 'actions', label: 'Actions', class: "w-10 hide-title" }
            ],
        };
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
        })
    },
    async mounted(){
        
        this.$root.$on('open-sidebar-orders', this.openSideBarOrder);
    },
    methods: {
        formatCurrency(value){
            return formatData.currency(value);
        },
        openSideBarOrder(data) {
            this.dataOrder = data;
            this.$refs["orderDetails"].show();
        },
        hideModal() {
            this.$refs['orderDetails'].hide();
        },
    }
}
