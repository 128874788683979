
import { mapGetters } from "vuex";
import { formatData } from "../../constants/config.js";
import 'vue-slider-component/theme/antd.css';
export default {
	data() {
		return {
			back_url			: process.env.VUE_APP_HEFESTO_API_URL_FILE, 
			heightSidebar	: 0,
			openSidebar		: false,
			fixedSidebar	: false,
		};
	},
	components: {
		vueSlider: () => import("vue-slider-component"),
	},
	async mounted() {
		window.addEventListener('scroll', this.positionSidebar);
	},
	computed: {
		...mapGetters({
			getterFiltersCollection: "getterFiltersCollection",
		}),
		getProductPriceFilter(){
			return [this.getterFiltersCollection[0].values.min_price, this.getterFiltersCollection[0].values.max_price];
		}
	},
	methods: {
		async positionSidebar( event ) {
			this.fixedSidebar 	= window.top.scrollY >= 273;
			this.heightSidebar 	= ( window.innerWidth >= 768 && window.top.scrollY < 273 ) ? 193 - window.top.scrollY : 40;
		},
		openLayoutSidebar() {
			this.openSidebar = true;
			this.positionSidebar();
		},
		closeLayoutSidebar() {
			this.openSidebar = false
		},
		isActive(filterItem) {
			return false;
		},
		appliedFilter(select_filter) {
			
			if( select_filter.name && !select_filter.value.checked ){
				
				select_filter.id = `filter-${ select_filter.handle }-${ select_filter.sub_filter ? select_filter.sub_filter.handle : '' }-${ select_filter.value.handle }`.replace( "--", "-" );
				this.$root.$emit("add-filter", select_filter);
			}
			else if( select_filter.value?.checked ){
				
				select_filter.id = `filter-${ select_filter.handle }-${ select_filter.sub_filter ? select_filter.sub_filter.handle : '' }-${ select_filter.value.handle }`.replace( "--", "-" );
				this.$root.$emit("remove-filter", select_filter);
			} 
			else{
				
				this.$root.$emit("add-filter", { id: 'filter-price', name: 'Price', handle: 'price', sub_filter: null, value: select_filter });
			}
		},
		toggleSidebarBlock() {
			this.openBlock = !this.openBlock;
		},
		formatCurrency(price, totalAmount = null){
			return formatData.currency(price, totalAmount);
		}
	}
};
