
export default {
	data(){
		return {
			typeErr: null
		}
	},
	mounted() {
		this.typeErr = this.$route.query.err || null
	},
}
