
import { mapGetters } from 'vuex';
export default {
	props: ['homeNavigation'],
	components:{
		navbarItem: () => import('../navbar/navbar-item.vue')
	},
	data() {
		return {
			openMobileNav: false,
			activeItem: '',
			activeChildItem: '',
			activeMegaChild: '',
			navigation: null
		}
	},
    computed: {
		...mapGetters({
			getterCompanyData: 'getterCompanyData'
		}),
        getNavigation(){
            
            return this.getterCompanyData?.navigations.header.nav_tree || [];
        }
    },
    methods: {
        setActive(menuItem) {
            
            this.activeItem = this.activeItem === menuItem ? '' : menuItem;
        }
	}
}
