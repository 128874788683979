
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { formatData } from "../../constants/config.js";
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    components:{
        DateRangePicker
    },
    props: ["type_table", "table_fields"],
    filters: {
        date (value) {
            if (!value){
                return ''
            }
            else{
                return moment( new Date( value ) ).format("MMMM DD, YYYY")
            }
        }
    },
    data() {
        return {
            isLoading           : true,
            
            listItems           : [],
            billingFileUrl      : `${ process.env.VUE_APP_HEFESTO_API_URL }/app-billing`,
            
            min_date			: moment( new Date() ).startOf("month").startOf("day").format("MMMM DD, YYYY"),
            max_date			: moment( new Date() ).endOf("day").format("MMMM DD, YYYY"),
            searchField			: "",
            paginateDetails	    : {
                perPage     : 20,
                currentPage : 1,
                lastPage    : 0,
                fromPage    : 0,
                toPage      : 0,
                totalPages  : 0
            },
            pageSizes	        : [10, 20, 50, 100],
            dateFilterOptions	: ["Custom Range", "This Month", "Last Month", "This Year", "Last Year"],
            selectDateFilter	: "This Month",
            range_dates			: {
                startDate: moment( new Date() ).startOf("month").startOf("day").format('MMMM DD, YYYY'),
                endDate: moment( new Date() ).endOf("day").format('MMMM DD, YYYY'),
            },
            isTableBusy	        : true,
            
            quick_ranges: {
                'Today'     : [
                    new Date( moment( new Date() ).startOf("day") ), 
                    new Date( moment( new Date() ).endOf("day") )],
                'Yesterday' : [
                    new Date( moment( new Date() ).subtract(1, 'day').startOf("day") ), 
                    new Date( moment( new Date() ).subtract(1, 'day').endOf("day") )],
                'This month': [
                    new Date( moment( new Date() ).startOf("month").startOf("day") ), 
                    new Date( moment( new Date() ).endOf("day") )],
                'Last month': [
                    new Date( moment( new Date() ).subtract(1, 'month').startOf("month").startOf("day") ), 
                    new Date( moment( new Date() ).subtract(1, 'month').endOf("month").endOf("day") )],
                'This year' : [
                    new Date( moment( new Date() ).startOf("year").startOf("day") ), 
                    new Date( moment( new Date() ).endOf("day") )],
                'Last year' : [
                    new Date( moment( new Date() ).subtract(1, 'year').startOf("year").startOf("day") ), 
                    new Date( moment( new Date() ).subtract(1, 'year').endOf("year").endOf("day") )],
            }
        };
    },
    async mounted(){
        
        if( this.getterLogin ){
            
            await this.updateTableData();
        }
    },
    computed: {
        ...mapGetters({
            getterLogin                     : 'getterLogin',
            getterUser                      : 'getterUser',
            getterSatement                  : "getterSatement",
            getterTransactions              : "getterTransactions",
            getterOrders                    : "getterOrders",
            getterPriceCatalog              : "getterPriceCatalog",
            getterDownloadTransactionsFile  : "getterDownloadTransactionsFile",
            getterDownloadPriceCatalog      : "getterDownloadPriceCatalog",
        }),
        getListItems(){
            
            return this.listItems;
        },
        getTitleExport(){
            let title_export = "";
            if( this.type_table == 'transactions' ){
                title_export = `Download Transactions File ${ this.momentFormat( this.range_dates.startDate ) } - ${ this.momentFormat( this.range_dates.endDate ) }`;
            }
            else if( this.type_table == 'price-catalog' ){
                title_export = `Download Price Catalog File - ${ moment( new Date() ).format("YYYY-MM-DD") }`;
            }
            return title_export;
        }
    },
    methods:{
        ...mapActions({
            getTransactions                 : "getTransactions",
            getOrders                       : "getOrders",
            getPriceCatalog                 : "getPriceCatalog",
            getStatementByCustomer          : "getStatementByCustomer",
            downloadExportTransactionsFile  : "downloadExportTransactionsFile",
            deleteExportTransactionsFile    : "deleteExportTransactionsFile",
            downloadPriceCatalog            : "downloadPriceCatalog",
            deletePriceCatalog              : "deletePriceCatalog"
        }),
        formatCurrency(price){
            return formatData.currency(price);
        },
        momentFormat(date) {
            return moment( new Date(date) ).format("YYYY-MM-DD");
        },
        async updateRangeDate (values) {
            this.range_dates.startDate = moment( new Date(values.startDate) ).format('MMMM DD, YYYY');
            this.range_dates.endDate = moment( new Date(values.endDate) ).format('MMMM DD, YYYY');
            await this.updateTableData();
        },
        async updateTableData(){
            this.isTableBusy = true;
            this.paginateDetails.currentPage = 1;
            this.onChangePage(1);
            this.updatePaginateTable([]);
            
            let request_body = { page: 1, search: this.searchField, min_date: this.momentFormat( this.range_dates.startDate ), max_date: this.momentFormat( this.range_dates.endDate ) };
            
            if( this.type_table == "transactions" ){
                
                await this.getTransactions(request_body).then( (result_transactions) => {
                    
                    this.listItems = this.getterTransactions;
                    this.resultListItems();
                });
            }
            else if( this.type_table == "orders" ){
                
                await this.getOrders(request_body).then( (result_orders) => {
                    
                    this.listItems = this.getterOrders;
                    this.resultListItems();
                });
            }
            else if( this.type_table == "price-catalog" ){
                
                await this.getPriceCatalog().then( (result_price_catalog) => {
                    
                    this.listItems = this.getterPriceCatalog;
                    this.resultListItems();
                });
            }
        },
        resultListItems(){
            
            this.updatePaginateTable(this.getListItems);
            this.$refs["table_data"]?.refresh();
            this.isTableBusy = false;
        },
        updatePageinfo( page ){
            this.paginateDetails.toPage = ( this.paginateDetails.perPage * page ) > this.paginateDetails.totalPages ? this.paginateDetails.totalPages : ( this.paginateDetails.perPage * page );
            this.paginateDetails.fromPage = ( this.paginateDetails.perPage * ( page - 1 ) ) + 1;
        },
        updatePaginateTable(rows){
            this.paginateDetails.currentPage = 1;
            this.paginateDetails.totalPages = rows.length;
            this.updatePageinfo( this.paginateDetails.currentPage );
        },
        onChangePage(page) {
            this.updatePageinfo( page );
        },
        changePageSize(perPage) {
            this.paginateDetails.perPage = perPage;
            this.updatePageinfo( this.paginateDetails.currentPage );
        },
        async searchTable(search_value) {
            this.searchField = search_value;
            await this.updateTableData();
        },
        async cleanSearchTable(){
            this.searchField = "";
            await this.updateTableData();
        },
        downloadExportFile( data_file ) {
            
            if( ![null, ""].includes( data_file ) ){
                
                document.getElementById("download_file").href = `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }${ data_file }`;
                document.getElementById("download_file").download = `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }${ data_file }`.substring( `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }${ data_file }`.lastIndexOf('/') + 1 );
                document.getElementById("download_file").click();
                setTimeout(async () => {
                    if( this.type_table == 'transactions' ){
                        
                        await this.deleteExportTransactionsFile({ url_file: data_file });
                    }
                    else if( this.type_table == 'price-catalog' ){
                        
                        await this.deletePriceCatalog({ url_file: data_file });
                    }
                }, 3000);
            }
        },
        async createExportTable(){
            
            if( this.type_table == 'transactions' ){
                
                await this.downloadExportTransactionsFile({ page: 1, min_date: this.momentFormat( this.range_dates.startDate ), max_date: this.momentFormat( this.range_dates.endDate ) }).then( (result_file) => {
                    
                    this.downloadExportFile( this.getterDownloadTransactionsFile );
                });
            }
            else if( this.type_table == 'price-catalog' ){
                
                await this.downloadPriceCatalog().then( (result_file) => {
                    
                    this.downloadExportFile( this.getterDownloadPriceCatalog );
                });
            }
        },
        showOrderDetails(dataOrder){
            
            this.$root.$emit('open-sidebar-orders', dataOrder);
        }
    }
}
