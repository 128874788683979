
import { mapGetters, mapActions } from "vuex";
import { effectSlider, randomNumber }  from '../../constants/config.js';
export default {
	props:["banner", "title", "message", "source"],
	components: {
		logoSlider 		: () => import( '../../components/home/logo-slider.vue' ),
		stepRegister	: () => import( '../../components/account/step-register.vue' ),
		faq         	: () => import( '../../components/account/faq.vue' ),
	},
	data() {
		return {
			products: [],
			category: [],
			showquickviewmodel: false,
			showcomparemodal: false,
			showcartmodal: false,
			quickviewproduct: {},
			comapreproduct: {},
			cartproduct: {},
			imagePath: require(`@/assets/images/${ this.banner }`),
			position_form: 0,
			back_file: process.env.VUE_APP_HEFESTO_API_URL_FILE,
			list_reviews: [],
			number_group_review: 0
		}
	},
	async mounted() {
		window.addEventListener('load', this.loadPage);
		this.$root.$on('move-custom-form', this.moveScrollRegisterForm );
		
		await this.getReviews().then( (result_reviews) => {
			
			this.number_group_review = randomNumber(1, 20);
			this.list_reviews = this.getterReviews[this.number_group_review - 1].list;
		});
	},
	computed: {
		...mapGetters({
			getterReviews: "getterReviews"
		}),
		listReviews(){
			return this.list_reviews;
		}
	},
	methods: {
		...mapActions({
			getReviews: "getReviews"
		}),
		loadPage(event){
			setInterval(() => {
				effectSlider('slider-register-benefits', 3, 3000 );
			}, 6000);
			
			let count_reviews = 1;
			setInterval(() => {
				effectSlider('slider-cata1og-plans', 3, 4000 );
				if( count_reviews == 4 ){
					
					this.number_group_review = randomNumber(1, 20, this.number_group_review);
					this.list_reviews = this.getterReviews[this.number_group_review - 1].list;
					count_reviews = 1;
				}
				count_reviews += 1;
				effectSlider('slider-reviews', 4, 4000 );
			}, 8000);
		},
		moveScrollRegisterForm(valid_move) {
			if (process.client) {
				this.position_form = window.scrollY + document.getElementById('register_form').getBoundingClientRect().top - ( window.innerWidth <= 768 ? 89 : 150 );
				
				this.$nextTick(() => {
					
					setTimeout(() => {
						window.scrollTo({
							top: Math.round( this.position_form >= 0 ? this.position_form : ( this.position_form ) * -1 ),
							left: 0,
							behavior: "smooth"
						});
					}, 300);
				});
			}
		}
	}
}
