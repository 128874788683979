
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { formatData, calcDiscountPrice, arrayHelpers } from '../../constants/config.js';
export default {
    components: {
        vcLoading		: () => import("../../components/pages/loading.vue"),
        breadcrumbs     : () => import('../../components/pages/breadcrumbs.vue'),
        viewedProducts  : () => import( '../../components/product/viewed-products.vue' ),
        vcFormatPrice   : () => import( '../../components/product/format-price.vue' ),
        countDown   	: () => import( '../../components/pages/count-down.vue' )
    },
    data() {
        return {
            isLoading: true,
            back_url: process.env.VUE_APP_HEFESTO_API_URL_FILE,
            product: null,
            existColors: null,
            variantsTableItems: [],
            selected_bundles: {
                current : [],
                custom  : [],
                details : { 
                    items: {
                        current   : 0,
                        custom  : 0
                    }, 
                    total: 0,
                    total_advance: 0,
                    valid_custom: false
                }
            },
            totalOrder: {
                cart: {
                    items: 0,
                    total: 0
                },
                preorder: {
                    items: 0,
                    total: 0
                }
            },
            activeColor: null,
            mobileProductVariants: false,
            showTopSelection: false,
            show_mobile_carousel: false,
            discount_stock: {
                value: 0,
                min_stock: 0
            },
            active_bundle: false
        };
    },
    async mounted() {
        
        this.mobileProductVariants = !window.innerWidth > 991;
        window.addEventListener('resize', this.responsiveProduct);
        window.addEventListener('scroll', this.responsiveProduct);
        window.addEventListener('load', this.responsiveProduct);
        
        let request_body = {
            handle: this.$route.params.handle
        };
        if( this.$route.params.handleCollection ){
            
            request_body.handle_collection = this.$route.params.handleCollection;
        }
        await this.getProduct(request_body).then( (result_product) => {
            
            let item_product = this.getterProduct;
            this.isLoading = null;
            if( this.getterProduct != "not-found" ){
                
                item_product.thumbnails = {
                    desktop: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( item_product.images ) ), 3 ),
                    mobile: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( item_product.images ) ), 2 )
                }
                this.addProductRecentlyViewd(item_product);
                this.existColors = item_product.options.find( (item)=> item.name == "Color" && item.values.length > 0 );
                this.existColors = this.existColors ? this.existColors : null;
                item_product.variants = item_product.variants.map( (item) => {
                    
                    if( item.discount_stock.value > 0 ){
                        this.discount_stock.value       = item.discount_stock.value;
                        this.discount_stock.min_stock   = item.discount_stock.min_stock;
                    }
                    return {
                        product_id			: item_product.shopify_id,
                        variant_id			: item.shopify_id,
                        sku					: item.sku,
                        options				: item.options,
                        image				: item.image,
                        price				: item.price,
                        discount_price		: item.discount_price,
                        discount_brand      : item_product.discounts.brand,
                        discount_stock      : item.discount_stock,
                        inventory_quantity	: item.inventory_quantity,
                        bundle_quantity		: 0,
                        quantity			: 0,
                        preorder			: 0,
                        show                : false
                    };
                });
                this.variantsTableItems = item_product.variants;
                if( item_product.bundle ){
                    
                    if( moment(item_product.bundle.started_at.split('T')[0]).startOf('day').diff(moment(), 'seconds') < 0 && moment(item_product.bundle.ended_at.split('T')[0]).endOf('day').diff(moment(), 'seconds') > 0 ){
                        
                        this.active_bundle = true;
                    }
                    item_product.bundle.selected_variants = item_product.bundle.selected_variants.reduce( (previous_item, current_item) => {
                        
                        if( current_item.variant ){
                            
                            previous_item.push({
                                variant_id      : current_item.variant.shopify_id,
                                product_id      : current_item.variant.product_id,
                                sku             : current_item.variant.sku,
                                title           : current_item.variant.title,
                                title_product   : current_item.variant.title_product,
                                options         : arrayHelpers.sortArray( current_item.variant.options, 'name' ),
                                image           : current_item.variant.image,
                                price           : current_item.price,
                                custom_quantity : 0,
                                current_quantity: current_item.quantity
                            });
                            if( current_item.variant.image ){
                                
                                item_product.images.push( { type: 'image', ...current_item.variant.image } );
                            }
                        }
                        return previous_item;
                    }, []);
                    
                    item_product.images = item_product.images.reduce( (previous_item, current_item) => {
                        
                        if( previous_item.findIndex( (item) => item.shopify_id == current_item.shopify_id ) < 0 ){
                            
                            previous_item.push( current_item );
                        }
                        return previous_item;
                    }, []);
                    item_product.thumbnails = {
                        desktop: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( item_product.images ) ), 3 ),
                        mobile: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( item_product.images ) ), 2 )
                    }
                    item_product.bundle.time_left = moment(item_product.bundle.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
                    this.$root.$emit("init-count-down", moment(item_product.bundle.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0);
                }
                if( this.existColors ){
                    
                    this.activeColor = this.existColors.values[0];
                    setTimeout(() => {
                        
                        this.changeVariantTable(this.activeColor);
                    }, 300);
                }
                else{
                    item_product.variants = item_product.variants.map( (item) => {
                        
                        item.show = true;
                        return item;
                    })
                }
            }
            this.product = item_product;
        });
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
            getterProduct: "getterProduct", 
            getterNotificationProductCart: "getterNotificationProductCart",
        }),
        selectVariants(){
            return this.variantsTableItems.filter( (item) => item.quantity > 0 && item.preorder > 0 );
        },
        getShowMobileCarousel(){
            return this.show_mobile_carousel;
        },
        getProcessProduct(){
            return this.product;
        },
        getDiscountStock(){
            return this.discount_stock;
        },
        getApplyDiscountStock(){
            return this.apply_discount_stock;
        },
        getTotalOrder(){
            return this.totalOrder;
        },
        getSelectedBundles(){
            return this.selected_bundles;
        },
        getErrorQuantity(){
            return this.getSelectedBundles.details.items.current + this.getSelectedBundles.details.items.custom == 0 ? false : !this.getSelectedBundles.details.valid_custom;
        },
        getQuantitySugest(){    
            return `${ Math.ceil( this.getSelectedBundles.details.items.custom / this.getProcessProduct.bundle.moq ) * this.getProcessProduct.bundle.moq } or ${ ( Math.ceil( this.getSelectedBundles.details.items.custom / this.getProcessProduct.bundle.moq ) + 1 ) * this.getProcessProduct.bundle.moq }`;
        },
        getActiveBundle(){
            return this.active_bundle;
        }
    },
    methods: {
        ...mapActions({
            getProduct: 'getProduct',
            addNotification: 'addNotification',
            actionCart: 'actionCart'
        }),
        formatCurrency(price, totalAmount = null){
            return formatData.currency(price, totalAmount)
        },
        cleanCart(){
            this.totalOrder = {
                cart: {
                    items: 0,
                    total: 0
                },
                preorder: {
                    items: 0,
                    total: 0
                }
            };
            if( this.existColors ){
                
                this.activeColor = this.existColors.values[0];
                this.changeVariantTable( this.activeColor );
            }
            this.variantsTableItems = this.variantsTableItems.map( (item) => {
                
                item.quantity = 0;
                item.preorder = 0;
                
                return item;
            })
        },
        addProductsToCart() {
            
            if( this.product.moq == 0 || ( this.product.moq > 0 && this.variantsTableItems.reduce( (previous_item, current_item) => { previous_item += current_item.quantity; return previous_item; }, 0) >= this.product.moq ) ){
                
                this.actionCart({ action: 'add', products: this.variantsTableItems.filter( (item) => { return item.quantity + item.preorder > 0; }) } ).then( (result_cart) => {
                    
                    this.variantsTableItems = this.variantsTableItems.map( (item) => { item.quantity = 0; item.preorder = 0; return item; });
                    this.generateNotificationsProduct();
                });
            }
            else{
                
                this.addNotification({ 
                    content: { type: 'request' }, 
                    type: 'warning', 
                    data:{
                        title: `Product MOQ`, 
                        message: `This product has a Minimum Order Quantity (MOQ) of ${ this.product.moq } units`,
                        app_version: this.$cookies.get('app-version')
                    }
                });
            }
        },
        preorderBundleProducts(){
            
            this.$cookies.set('pre-order-bundle', JSON.stringify({
                id: this.getProcessProduct.bundle._id,
                current: `${ this.getSelectedBundles.current[0].variant_id }:${ this.getSelectedBundles.current[0].bundle_quantity }`,
                custom: this.getSelectedBundles.custom.map( (item) => {
                    return `${ item.variant_id }:${ item.custom_quantity }`;
                })
            }), { path: '/', maxAge: 60 * 60 * 24 * 7 });
            this.$router.push({ path: `/checkout/pre-order-bundle?type=advance&bundle=${ this.getProcessProduct.bundle._id }&handle=${ this.getProcessProduct.handle }` });
        },
        createNotificationGroup(status, type, message = '') {
            
            return {
                id      : status,
                products: this.getterNotificationProductCart.filter(item => item.status === status),
                type    : type,
                message : message
            };
        },
        generateNotificationsProduct(){
            
            let notification_groups = [
            this.createNotificationGroup('full-add', 'success'),
            this.createNotificationGroup('partial-add', 'warning', "These References were partially added, because they exceed the available stock"),
            this.createNotificationGroup('max-stock', 'error', "This products has its maximum stock in the cart")
            ];
            
            for (const item_notification of notification_groups) {
                
                if( item_notification.products.length > 0 ) {
                    
                    setTimeout(() => {
                        let data_notification = {
                            title   : item_notification.id != 'max-stock' ? `Add to Cart Product` : 'Failed Add to Cart', 
                            message : item_notification.id == 'full-add' ? `${ item_notification.total_products } items successfully added` : item_notification.message,
                        }
                        if( item_notification.id != 'max-stock' ){
                            
                            data_notification.app_version = this.$cookies.get('app-version');
                        }
                        this.addNotification({ 
                            content : { 
                                type    : 'add-cart-product', 
                                image   : item_notification.products[0].image, 
                                brand   : item_notification.products[0].brand,
                                status  : item_notification.products[0].type, 
                                products: item_notification.products,
                            }, 
                            type    : item_notification.type, 
                            data    : data_notification
                        });
                    }, 500);
                }
            }
            this.cleanCart();
        },
        slideTo(index) {
            
            this.$refs["carousel_product"].setSlide(index);
        },
        changeVariantTable(selectColor) {
            
            this.activeColor = selectColor;
            this.variantsTableItems = this.variantsTableItems.map( (item_v) => {
                
                item_v.show = item_v.options.findIndex( (item) => item.name == "Color" && item.value == this.activeColor.name ) >= 0;
                return item_v;
            });
            let first_variant = this.product.variants.find( (item) => item.options.find( (item_o) => item_o.name == "Color" && item_o.value == this.activeColor.name ) );
            
            if( first_variant?.image ){
                
                let index_image = this.product.images.findIndex( (item) => item.shopify_id == first_variant.image.shopify_id );
                
                this.slideTo( index_image );
            }
        },
        isNumber(event, variant_item, type){
            
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 48 || keyCode > 57) || parseInt(document.getElementById(`${ variant_item.variant_id }_${ type }`).value) == 0 && parseInt(event.key) == 0 ) {
                
                event.preventDefault();
            }
            else if( parseInt(document.getElementById(`${ variant_item.variant_id }_${ type }`).value) == 0 && parseInt(event.key) > 0 ){
                
                document.getElementById(`${ variant_item.variant_id }_${ type }`).value = null;
            }
        },
        validNumber(number){
            
            return ( isNaN( parseInt(number) ) ? 0 : parseInt(number) );
        },
        changeInputValue(item_product, type_field, acronym_field) {
            
            if( type_field == 'quantity' ){
                
                item_product.quantity = ( this.product.sales_limit == 0 || ( this.product.sales_limit > 0 && item_product.quantity <= this.product.sales_limit ) ) ? item_product.quantity : this.product.sales_limit;
                
                if( item_product.quantity <= item_product.inventory_quantity ){
                    
                    document.getElementById(`${ item_product.variant_id }_${ acronym_field }`).value = this.validNumber(item_product.quantity);
                }
                else{
                    
                    document.getElementById(`${ item_product.variant_id }_${ acronym_field }`).value = this.validNumber(item_product.inventory_quantity);
                }
            }
            item_product[type_field] = this.validNumber( document.getElementById(`${ item_product.variant_id }_${ acronym_field }`).value );
            
            if( type_field == 'quantity' ){
                
                this.variantsTableItems[this.variantsTableItems.findIndex( (item_v) => item_v.sku == item_product.sku )].quantity = item_product.quantity;
            }
            if( ['quantity', 'preorder'].includes(type_field) ) {
                
                this.totalOrder = this.variantsTableItems.reduce( (previous_item, current_item) => {
                    
                    previous_item.cart.items += this.validNumber(current_item.quantity);
                    previous_item.cart.total += ( this.formatCurrency( calcDiscountPrice( current_item.price, current_item.discount_brand, this.validNumber(current_item.quantity) ) * this.validNumber(current_item.quantity), false ).number );
                    
                    previous_item.preorder.items += this.validNumber(current_item.preorder);
                    previous_item.preorder.total += ( this.formatCurrency( calcDiscountPrice( current_item.price, current_item.discount_brand, this.validNumber(current_item.preorder) ) * this.validNumber(current_item.preorder), false ).number );
                    
                    return previous_item;
                }, { cart: { items: 0, total: 0 }, preorder: { items: 0, total: 0 } });
                
                if( this.validNumber( item_product.preorder ) + this.validNumber( item_product.quantity ) > 0 ) {
                    
                    item_product[type_field] = this.validNumber( item_product[type_field] );
                }
            }
            else {
                
                this.selected_bundles = this.variantsTableItems.reduce( (previous_item, current_item) => {
                    
                    if( current_item.bundle_quantity > 0 ) {
                        
                        previous_item.details.items.current += this.validNumber(current_item.bundle_quantity);
                        previous_item.details.total += ( this.formatCurrency( ( current_item.price * this.validNumber(current_item.bundle_quantity) ) * 100, false ).number );
                        previous_item.details.total_advance = ( this.formatCurrency( calcDiscountPrice( previous_item.details.total, 100 - this.getProcessProduct.bundle.pay_percentage.current, 1 ), false ).number );
                        previous_item.current.push( current_item );
                    }
                    return previous_item;
                }, { current: [], custom: [], details: { items: { current: 0, custom: 0 }, total: 0, total_advance: 0, valid_custom: true } } );
                if( this.selected_bundles.details.items.current == 0 ){
                    
                    this.selected_bundles.details.valid_custom = false;
                }
                this.selected_bundles = this.getProcessProduct.bundle.selected_variants.reduce( (previous_item, current_item) => {
                    
                    if( current_item.custom_quantity > 0 ) {
                        
                        previous_item.details.items.custom += this.validNumber(current_item.custom_quantity);
                        previous_item.custom.push( current_item );
                    }
                    return previous_item;
                }, this.selected_bundles );
                
                if( this.selected_bundles.details.items.custom > 0 ){
                    
                    this.selected_bundles.details.valid_custom = this.selected_bundles.details.items.custom % this.getProcessProduct.bundle.moq === 0;
                    
                    if( this.selected_bundles.details.items.custom % this.getProcessProduct.bundle.moq === 0 ){
                        
                        this.selected_bundles.details.total += this.formatCurrency( this.variantsTableItems[0].price * Math.round( this.selected_bundles.details.items.custom / this.getProcessProduct.bundle.moq ) * 100, false ).number;
                        this.selected_bundles.details.total_advance += ( this.formatCurrency( calcDiscountPrice( this.formatCurrency( this.variantsTableItems[0].price * Math.round( this.selected_bundles.details.items.custom / this.getProcessProduct.bundle.moq ) * 100, false ).number, 100 - this.getProcessProduct.bundle.pay_percentage.custom, 1 ), false ).number );
                    }
                }
                if( this.validNumber( item_product[type_field] ) > 0 ) {
                    
                    item_product[type_field] = this.validNumber( item_product[type_field] );
                }
            }
        },
        addProductRecentlyViewd(item_product) {
            
            if( this.$cookies.get('recently-viewed') ) {
                
                let recently_view_products = this.$cookies.get('recently-viewed');
                
                if( recently_view_products.length <= 8 && !recently_view_products.find((item_r)=> item_r == item_product.id) ) {
                    
                    recently_view_products.push(item_product.id);
                    this.$cookies.set('recently-viewed', JSON.stringify(recently_view_products), { path: '/', maxAge: 60 * 60 * 24 * 7 });
                }
            } 
            else if( item_product ){
                
                this.$cookies.set('recently-viewed', JSON.stringify([item_product.id]), { path: '/', maxAge: 60 * 60 * 24 * 7 });
            }
        },
        responsiveProduct(){
            
            this.show_mobile_carousel = window.innerWidth <= 1200;
            
            this.mobileProductVariants = !window.innerWidth > 991;
            
            let section_breadcrumb      = document.getElementById('breadcrumb-section')     ? document.getElementById('breadcrumb-section').offsetHeight    : 0;
            let section_slider          = document.getElementById('slider-product')         ? document.getElementById('slider-product').offsetHeight        : 0;
            let section_description     = document.getElementById('product-description')    ? document.getElementById('product-description').offsetHeight   : 0;
            let section_top_selection   = document.getElementById('top-seleccion-color')    ? document.getElementById('top-seleccion-color').offsetHeight   : 0;
            let section_header          = document.getElementById('header-default')         ? document.getElementById('header-default').offsetHeight        : 0;
            
            let calc_top_selection = ( section_breadcrumb + section_slider + section_description - section_top_selection - section_header );
            
            if( window.top.scrollY >= calc_top_selection && calc_top_selection > 0 ){
                
                this.showTopSelection = true;
            }
            else{
                
                this.showTopSelection = false;
            }
        }
    }
};
