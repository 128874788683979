
import { mapGetters, mapActions } from 'vuex'
export default {
	props: ['layout', 'openSidebar'],
	components:{
		navbarItem: () => import('../../components/navbar/navbar-item.vue'),
		profileImage: () => import('../../components/account/profile-image.vue')
	},
	data() {
		return {
			navigation		: null
		}
	},
	mounted() {
	},
	computed:{
		...mapGetters({
			getterLogin			: 'getterLogin',
			getterUser			: 'getterUser',
			getterCompanyData	: 'getterCompanyData',
			getterLevel			: 'getterLevel',
		}),
		getNavigation(){
			return this.getterCompanyData?.navigations.header.nav_tree || [];
		}
	},
	methods: {
		...mapActions({
			logoutUser: 'logoutUser'
		}),
		executeCloseSidebar(value) {
			this.$root.$emit('close-sidebar', false);
		},
		logout() {
			this.logoutUser();
			this.$router.go();
		},
	}
}
