
export default {
    props: {
        timeLeft: {
            type: Number,
            default: 0
        }
    },
	data() {
		return {
			time_left	: 0,
			count_down 	: { 
				days	: 0, 
				hours	: 0, 
				minutes	: 0, 
				seconds	: 0 
			}
		};
	},
	async mounted() {
		
		this.initCountDown(this.timeLeft);
		this.excuteCountDown();
		this.$root.$on("init-count-down", this.initCountDown);
		setInterval(this.excuteCountDown, 1000);
	},
	computed: {
		getTimeLeft(){
			return this.time_left;
		},
		getCountDown(){
			return this.count_down;
		}
	},
	methods: {
		initCountDown(time_left){
			
			this.time_left = time_left;
			this.count_down = {
				days	: Math.floor( this.getTimeLeft / (60 * 60 * 24) ),
				hours	: Math.floor( (this.getTimeLeft % (60 * 60 * 24)) / (60 * 60) ),
				minutes	: Math.floor( (this.getTimeLeft % (60 * 60)) / 60 ),
				seconds	: this.getTimeLeft % 60
			};
		},
		excuteCountDown() {
			
			if( this.getTimeLeft >= 0 ){
				
				this.count_down = {
					days	: Math.floor( this.getTimeLeft / (60 * 60 * 24) ) > 9 ? Math.floor( this.getTimeLeft / (60 * 60 * 24) ) : `0${ Math.floor( this.getTimeLeft / (60 * 60 * 24) ) }`,
					hours	: Math.floor( (this.getTimeLeft % (60 * 60 * 24)) / (60 * 60) ) > 9 ? Math.floor( (this.getTimeLeft % (60 * 60 * 24)) / (60 * 60) ) : `0${ Math.floor( (this.getTimeLeft % (60 * 60 * 24)) / (60 * 60) ) }`,
					minutes	: Math.floor( (this.getTimeLeft % (60 * 60)) / 60 ) > 9 ? Math.floor( (this.getTimeLeft % (60 * 60)) / 60 ) : `0${ Math.floor( (this.getTimeLeft % (60 * 60)) / 60 ) }`,
					seconds	: this.getTimeLeft % 60 > 9 ? this.getTimeLeft % 60 : `0${ this.getTimeLeft % 60 }`
				};
				
				this.time_left--;
			}
			else{
				
                this.$root.$emit('end-count-down');
			}
		}
	}
};
