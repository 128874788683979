
import { mapGetters, mapActions } from "vuex";
export default {
	props: ["productId"],
	components: {
		productCollection: () => import( "../product/product-collection.vue" ),
	},
	async mounted() {
		let filter_recently_viewed = this.$cookies.get("recently-viewed").filter( (item) => item !== this.productId );
		if( filter_recently_viewed.length > 0 ){
			await this.getProductViewed({ ids: JSON.stringify( filter_recently_viewed ) });
		}
	},
	methods: {
		...mapActions({
			getProductViewed: "getProductViewed"
		})
	},
	computed: {
		...mapGetters({
			getterProductsRecentlyViewed: "getterProductsRecentlyViewed"
		}),
	}
};
